* {
  margin: 0;
  padding: 0;
}

:root {
  --redColor: red;
  --whiteColor: white;
  --blackColor: black;
  --blackColorWithOpacity: rgba(0, 0, 0, 0.64);
  --blackColorWithLitlleOpacity: rgba(0, 0, 0, 0.9);
  --aquaColor: aqua;
  --azureColor: azure;
  --brownColor: brown;
  --gradientDark: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.9),
    rgba(50, 50, 50, 0.5)
  );
}
/**------------Google fonts----------------**/
.rubik-dirt-regular {
  /* font-family: "Rubik Dirt", system-ui;
  font-weight: 400;
  font-style: normal; */
  font-family: "Lunasima", sans-serif;
  font-weight: 600;
  font-style: normal;
}
/* .rubik-wet-paint-regular {
  font-family: "Rubik Wet Paint", serif;
  font-weight: 400;
  font-style: normal;
} */
/* -----------Text font----------------------**/
p {
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Lunasima", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin-top: 0.3rem;
  padding: 0.3rem;
}
/* li {
  margin-top: 0.3rem;
} */
/*-----------Accessibility-------------------**/
.accessibility_statement {
  background-color: var(--blackColor);
  color: var(--whiteColor);
  text-align: center;
  padding: 10px;
  margin-bottom: 2vh;
  margin-top: 2vh;
  font-style: italic;
  font-size: 14px;
  z-index: 1000;

  border-radius: 30px;
}
.accessibility_statement button {
  color: var(--blackColor);
  text-align: center;
  padding: 10px;
  margin-bottom: 2vh;
  margin-top: 2vh;
  font-style: italic;
  font-size: 14px;
  z-index: 1000;

  border-radius: 30px;
}

/*-------------Nav bar css-------------------**/

nav {
  width: 100%;
  height: 50px;
  color: var(--whiteColor);
  text-align: center;
  padding-top: 10px;
  margin-bottom: 0;
  background-color: var(--blackColor);
  position: fixed;
  top: 0;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  z-index: 1000;
}
nav ul {
  list-style-type: none;
  margin: 0;
  /* padding: 2vh; */
  display: inline;
  /* overflow: hidden; */
}
nav li {
  width: 10%;

  display: inline-block;
  padding: 10px;
  /* border-radius: 15px; */
  margin: 0;
  margin-left: 1vh;
  margin-right: 1vh;
  text-shadow: -1px -1px 0 var(--blackColor), 1px -1px 0 var(--blackColor),
    -1px 1px 0 var(--blackColor), 1px 1px 0 var(--blackColor);
  /* background-color: var(--blackColor); */
  /* background-color: rgb(0, 0, 0); */
  transition: all 0.5s ease;
}

nav li:hover {
  border-bottom: 5px solid var(--whiteColor);
  text-shadow: none;
  width: 12%;
}

nav ul a li {
  text-decoration: none;
  /* color: azure; */
  color: var(--azureColor);
}
body {
  background-image: url("./Images/background-trees.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  /* color: white; */
  color: var(--whiteColor);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  text-align: center;
}
/* ----------------------Header css-------------------**/
.header {
  margin: auto;
  margin-top: 100px;
}
/* ----------------------Home css-------------------**/

h1,
h2,
h3 {
  /* font-family: "Rubik Dirt", system-ui;
  font-weight: 400;
  font-style: normal; */
  font-family: "Lunasima", sans-serif;
  font-weight: 600;
  font-style: normal;
  margin-top: 0.3rem;
}
h1 {
  /* color: white;  */
  color: var(--whiteColor);
  text-shadow: -1px -1px 0 var(--blackColor), 1px -1px 0 var(--blackColor),
    -1px 1px 0 var(--blackColor), 1px 1px 0 var(--blackColor); /* יצירת גבול שחור סביב האותיות */
}
h2 {
  color: white; /* צבע האותיות */
  text-shadow: -1px -1px 0 var(--blackColor), 1px -1px 0 var(--blackColor),
    -1px 1px 0 var(--blackColor), 1px 1px 0 var(--blackColor); /* יצירת גבול שחור סביב האותיות */
}

.home {
  padding: 10px;
  border-radius: 10px;
  overflow: hidden;
  width: 80%;
  margin: auto;
  margin-top: 5vh;
  margin-bottom: 100px;
}
.home-text,
.opening-text {
  /* padding: auto;
  margin: auto; */
  margin-top: 5vh;
  margin-bottom: 5vh;
  border-radius: 15px;
  background-color: var(--blackColorWithOpacity);
  width: 100%;

  /* background-color: rgb(0, 0, 0); */
  /* background-color: rgba(0, 0, 0, 0.64); */
}
.home-text:hover,
.opening-text:hover {
  /* background-color: var(--blackColor); */
  /* transform: scale(1.1); */
  transform: scale(1.1);
  transition: transform 0.5s ease;
  background-color: var(--blackColorWithLitlleOpacity);
}
.home-images {
  /* margin: auto;
  padding: auto; */
  margin-top: 5vh;
  margin-bottom: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.home .home-images img {
  margin: 10px;
  border-radius: 10px;
  background-color: var(--blackColorWithOpacity);
  width: 49%;
  height: 50vh;
  object-fit: cover;
}
.opening-text ul {
  list-style: none;
}

/*----------------Footer css--------------**/
.footer {
  background-color: var(--blackColor);
  bottom: 0;
  width: 100%;
  height: auto;
  /* color: var(--whiteColor); */
  text-align: center;
  padding-top: 10px;
  margin-bottom: 0;
  font-style: italic;
  font-size: 14px;
  z-index: 1000;
  /* padding: auto; */
  padding-top: 3vh;
  padding-bottom: 3vh;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.footer .footer-icons ul {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  margin: auto;
  margin-top: 1vh;
  margin-bottom: 1vh;
  /* padding: auto; */
}

.footer .footer-icons ul li a img {
  width: 50px;
  height: auto;
  /* margin: auto;
  padding: auto; */
  transition: transform 0.5s ease;
}
.footer .footer-icons ul li:hover img {
  transform: rotate(360deg);
  /* transform: scale(1.1);
  transition: transform 0.5s ease; */
}
.footer-text {
  display: flex;
  justify-content: space-evenly;
  /* padding: auto;
  margin: auto; */
  margin-top: 3vh;
}
.footer-details-left ul {
  list-style: none;
  color: var(--azureColor);
}
.footer-copyright-right a {
  text-decoration: none;
  color: var(--azureColor);
}
/* .footer hr {
  border-style: dotted;
} */
/* ----------------------Info css-------------------**/

.info-container {
  position: relative;
  width: 80%;
  margin: auto;
  margin-top: 5vh;
  margin-bottom: 100px;
  border-radius: 30px;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--blackColorWithOpacity);
  z-index: 1;
}

.info-container:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
  background-color: var(--blackColorWithLitlleOpacity);
}
.info {
  position: relative;
  z-index: 2;
  color: var(--whiteColor);
  text-align: center;
  padding-top: 10px;

  padding: 20px;
}
.info h2 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.info p {
  margin-top: 10px;
  margin-bottom: 10px;
}
.contact-info ul {
  list-style: none;
  margin: 20px;
}
.contact-info ul li a {
  color: var(--whiteColor);
  text-decoration: none;
}
.contact-info ul li a:hover {
  text-decoration: underline;
}
.info .embded-vidoes {
  width: 100%;
  margin: auto;
}
.info .embded-vidoes ul {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}
.info .embded-vidoes ul li iframe {
  width: 200px;
  height: auto;
  /* transition: width 2s; */
  transition: width 2s, height 4s;
  transform: ease-in-out;
}

.info .embded-vidoes ul li img {
  margin: auto;
  margin-top: 20%;
  height: 100px;
  cursor: pointer;
}
.farm-team {
  /* margin: auto;
  padding: auto; */
  margin-top: 5vh;
  margin-bottom: 5vh;
  min-width: 100%;
  /* min-height: max-content; */
  /* height: 50vh; */
}
.farm-team ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style: none;
}
.farm-team ul li {
  max-width: 20%;
  min-width: 20%;
  margin-right: 10px;
  margin-left: 10px;
}

.farm-team ul li .team-images img {
  width: 150px;
  max-width: 150px;
  height: auto;
  transition: width 2s;
  transition: height 4s;
  border-radius: 90px;
  margin: auto;
  margin-top: 20%;
}
.farm-team ul li .team-images img:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}
.farm-team ul li .team-images h4,
.farm-team ul li .team-images p {
  text-shadow: -1px -1px 0 var(--blackColor), 1px -1px 0 var(--blackColor),
    -1px 1px 0 var(--blackColor), 1px 1px 0 var(--blackColor);
  background-color: var(--blackColorWithOpacity);
  border-radius: 10px;
}
.farm-team ul li .team-images h4:hover,
.farm-team ul li .team-images p:hover {
  background-color: var(--blackColor);

  transform: scale(1.1);
  transition: transform 0.5s ease;
}
.arrow-img {
  height: 100px;
  max-height: 200px;
  width: auto;
  margin: 10px;
  margin-top: 50%;
  cursor: pointer;
}

/* ----------------------Galery css-------------------**/
.galery {
  margin: auto;
  margin-bottom: 100px;
  margin-top: 5vh;
}

.galery img {
  width: 30vh;
  height: 200px;
  margin: 10px;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}

.galery .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--blackColorWithOpacity);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.galery .overlay img {
  width: 90vh;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 10px;
  z-index: 1001;
}
/*-----------------------Posts css----------------**/
.posts {
  margin: auto;
  margin-bottom: 100px;
  margin-top: 5vh;
}

.post {
  width: 50%;
  border: 1px solid #ddd;
  padding: 20px;
  margin: auto;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: var(--blackColor);
  opacity: 0.7; /* שקיפות בסיסית */
  transition: opacity 0.3s ease; /* מעבר עדין */
}

.post:hover {
  opacity: 0.9; /* ברגע שמעבירים את העכבר על הפוסט */
}

.post-author {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.circle-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.author-details {
  font-size: 14px;
}
.post-content p {
  text-align: right;
}
/*----------------------Modal-content css----------------**/
.modal-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
}
/* ----------------------Media Query css-------------------**/
@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .home {
    width: 90%;
    display: flex;
    flex-direction: column;
    /* margin-bottom: 40vh; */
  }
  .home-images {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .home .home-images img {
    width: 90%;
    height: auto;
  }
}
.not-found {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--whiteColor);
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  font-family: "Arial", sans-serif;
  text-shadow: -1px -1px 0 var(--blackColor), 1px -1px 0 var(--blackColor);
  background-color: var(--blackColorWithLitlleOpacity);
}
#NagishLiBarStrip {
  /* z-index: 1501 !important; */
  background-color: white !important;
  margin-top: 15vh;
  object-fit: cover;
}
html nagishli#NagishLiTag > #NagishLiBar > #NagishLiBarStrip {
  background-color: transparent !important;
  color: #000 !important;
  font-size: 24px !important;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  text-shadow: none !important;
  cursor: pointer !important;
}

html nagishli#NagishLiTag > #NagishLiBar > #NagishLiBarStrip::before {
  content: "\f193"; /* Unicode של Font Awesome לכיסא גלגלים */
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  color: #333;
  background-color: white;
  padding: 10px;
  border-radius: 50%;
}

@media only screen and (max-width: 600px) {
  nav {
    width: 100%;
    padding: 0 0 4vh 0;

    background-color: var(--blackColorWithLitlleOpacity);
    /* border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px; */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    transition: background 0.3s ease;
  }
  /* ----------------------Home css---------------**/
  .home {
    width: 90%;
    display: flex;
    flex-direction: column;
    /* margin-bottom: 40vh; */
  }
  .home-images {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .home .home-images img {
    width: 90%;
    height: auto;
  }
  /*-------------------------Farm Team css-------------------**/
  .farm-team {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .farm-team ul {
    margin: 0;
    padding: 0;
  }
  .farm-team ul li {
    max-width: 30%;
    min-width: 30%;
    margin-right: 10px;
    margin-left: 10px;
  }
  /* ----------------------Footer css---------------**/
  .footer {
    width: 100%;
  }
  .footer .footer-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    /* padding: auto; */
  }

  .footer .footer-icons ul li a img {
    width: 45px;
  }

  .footer .footer-text .footer-copyright-right,
  .footer .footer-text .footer-details-left {
    width: 100%;
    margin: auto;
    text-align: center;
    margin-top: 3vh;
  }
  .posts {
    margin: 0;
    margin-top: 5vh;
    margin-bottom: 5vh;
    /* padding: 0; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
  }
  .post {
    max-width: 100%;
    width: 80%;
    margin: auto;
    margin-top: 2vh;
    /* padding: auto; */
    padding-left: 5%;
    padding-right: 5%;
  }
  /*-----------------Info css---------------**/
  .info .embded-vidoes ul {
    position: relative;
  }
  .info .embded-vidoes ul li iframe {
    width: 100%;
    height: auto;
    /* transition: width 2s; */
    transition: width 2s, height 4s;
    transform: ease-in-out;
  }
  .info .embded-vidoes ul li img {
    margin: 0;
    padding: 0;
    /* margin-top: 20%; */
    /* height: 7%; */
    width: 30%;
    cursor: pointer;
    position: absolute;
    /* right: 0; */
    /* display: none; */
  }
  .info .embded-vidoes ul li .arrow-img-right {
    right: 0;
    top: 20%;
  }
  .info .embded-vidoes ul li .arrow-img-left {
    left: 0;
    top: 20%;
  }
  /*----------------------Modal-content css----------------**/
  .modal-content {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 300px; */
  }
  .modal-content img {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 10px;
    margin: 0;
    padding: 0;
  }
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    cursor: pointer;
  }
}
